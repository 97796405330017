import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "grid mr-0 mt-0 pt-2 ml-0 justify-content-center" }
const _hoisted_2 = { class: "col-12 p-0" }
const _hoisted_3 = { class: "grid m-0 justify-content-center" }
const _hoisted_4 = { class: "flex col-12 justify-content-end align-items-center my-3" }
const _hoisted_5 = { class: "col-12" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_OpportunitiesTabView = _resolveComponent("OpportunitiesTabView")!
  const _component_Button = _resolveComponent("Button")!
  const _component_SalesOppsDataWrapper = _resolveComponent("SalesOppsDataWrapper")!
  const _component_Card = _resolveComponent("Card")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_OpportunitiesTabView)
      ])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _withDirectives(_createVNode(_component_Button, {
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.switchResultView(!_ctx.isResultsListView))),
          class: "split-sales-order-btn",
          icon: _ctx.isResultsListView ? 'pi pi-th-large' : 'pi pi-list',
          outlined: ""
        }, null, 8, ["icon"]), [
          [_directive_tooltip, _ctx.isResultsListView ? 'Kanban Table View' : 'List Table View']
        ]),
        _withDirectives(_createVNode(_component_Button, {
          class: "split-sales-order-btn",
          icon: "pi pi-filter-slash",
          outlined: "",
          onClick: _ctx.clearFilters
        }, null, 8, ["onClick"]), [
          [_directive_tooltip, 'Clear All Filters']
        ]),
        _createVNode(_component_Button, {
          class: "split-sales-order-btn",
          label: "Print/Export",
          icon: "pi pi-print",
          onClick: _ctx.printExportData
        }, null, 8, ["onClick"]),
        _createVNode(_component_Button, {
          "data-test": "newSalesOpps",
          label: "New Opportunity",
          icon: "pi pi-plus",
          onClick: _ctx.handleSalesOpp
        }, null, 8, ["onClick"])
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createVNode(_component_Card, null, {
          content: _withCtx(() => [
            _createVNode(_component_SalesOppsDataWrapper, {
              ref: "salesOppsTable",
              "customer-index": _ctx.index,
              customer: _ctx.getCustomerFields,
              "current-view": _ctx.currentView,
              isResultView: _ctx.isResultsListView,
              onUpdateBadge: _cache[1] || (_cache[1] = 
              (field, value) =>
                _ctx.$emit('update-badge', field, value)
            ),
              maxExportRows: 1000,
              onRowClick: _ctx.handleRowClick
            }, null, 8, ["customer-index", "customer", "current-view", "isResultView", "onRowClick"])
          ]),
          _: 1
        })
      ])
    ])
  ], 64))
}