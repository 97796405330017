
import { defineComponent } from "vue";
import SalesOppsDataWrapper from "@/components/Sales/SalesOppsDataWrapper.vue";
import Card from "primevue/card";
import Button from "primevue/button";
import OpportunitiesTabView from "@/components/UI/OpportunitiesTabView.vue";
import { mapActions, mapGetters } from "vuex";
import Utils from "@/utility/utils";

export default defineComponent({
  name: "SalesOpps",
  components: {
    Button,
    SalesOppsDataWrapper,
    Card,
    OpportunitiesTabView,
  },
  computed: {
    ...mapGetters({
      getRangeEnd: "saleOpps/getRangeEnd",
      getCurrPage: "saleOpps/getCurrPage",
      getFirstRow: "saleOpps/getFirstRow",
      getCustomer: "customerInquiry/getCustomer",
      getSalesOppsIds: "customerInquiry/getSalesOppsIds",
      getNewCustomerOppsCount: "customerInquiry/getNewCustomerOppsCount",
      getActiveTab: "customerInquiry/getActiveTab",
      getActiveIndexCustomer: "customerInquiry/getActiveIndexCustomer",
      getActiveCustomerOpenSalesOpps:
        "customerInquiry/getActiveCustomerOpenSalesOpps",
      getUserId: "session/getUserId",
    }),
    getCustomerFields(): any {
      const { cust_id, name, contact_id_items } = this.getCustomer(this.index);
      return {
        cust_id,
        name,
        contact_id_items,
      };
    },
  },
  props: {
    index: Number,
    currentView: String,
  },
  created() {
    this.$emit(
      "update-badge",
      "saleopp_ids",
      this.getSalesOppsIds(this.index)
        ? this.getSalesOppsIds(this.index).length
        : 0,
    );
    this.handleActiveOpportunityTab();
  },

  updated() {
    this.$emit(
      "update-badge",
      "saleopp_ids",
      this.getSalesOppsIds(this.index)
        ? this.getSalesOppsIds(this.index).length
        : 0,
    );
  },

  data() {
    return {
      order: false,
      isEditing: false as boolean,
      id: "",
      isResultsListView: true,
    };
  },
  methods: {
    ...mapActions({
      setFirstRow: "saleOpps/setFirstRow",
      pushSaleOppsId: "customerInquiry/pushSaleOppsId",
      removeSalesOpp: "customerInquiry/removeSalesOpp",
      addCustomerSalesOpp: "customerInquiry/addCustomerSalesOpp",
      addOpenedSalesOppCustomerInquiry:
        "customerInquiry/addOpenedSalesOppCustomerInquiry",
    }),
    handleActiveOpportunityTab() {
      const activeOpportunityTab = this.getActiveTab;
      const opportunites = this.getActiveCustomerOpenSalesOpps;

      const oppId =
        opportunites.length > 0 && opportunites[activeOpportunityTab - 1]
          ? opportunites[activeOpportunityTab - 1].record.id
          : "";

      if (oppId) {
        this.$router.push(
          `/customers/${this.getActiveIndexCustomer.customer.cust_id}/sales/opportunities/${oppId}`,
        );
      } else {
        this.$router.push("/customers/sales/opportunities");
      }
    },
    switchResultView(isList: boolean) {
      this.isResultsListView = isList;
    },
    printExportData() {
      (this.$refs as any).salesOppsTable.printTable();
    },
    clearFilters() {
      (this.$refs as any).salesOppsTable.clearAllFiltersAndFetch();
    },
    handleRowClick(event: any) {
      const customer = this.getCustomer(this.index);
      const cust_id = customer.cust_id;
      this.addOpenedSalesOppCustomerInquiry(event.data);
      this.$router.push(
        `/customers/${cust_id}/sales/opportunities/${event.data.id}`,
      );
    },
    handleSalesOpp() {
      const customer = this.getCustomer(this.index);
      const cust_id = customer.cust_id;

      const id = "New Opp-" + ((this.getNewCustomerOppsCount ?? 0) + 1);

      const itemToEdit = {
        id: id,
        assigned_to: this.getUserId,
        date: Utils.formatDate(new Date()),
        cust: cust_id,
      };
      this.addOpenedSalesOppCustomerInquiry(itemToEdit);
      this.$router.push(`/customers/${cust_id}/sales/opportunities/${id}`);
    },
  },
});
